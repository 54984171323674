import React from "react"
import Modal from "../bootstrap/modal";
import { Row, Col, Button } from "react-bootstrap";

export default function ServiceCheck({error, onClose= () => null}) {
  const errArray = {
    EBUILDING: "Unable to find your building. Please contact support or try again later.",
    EBUILDINGADDRESS: "Building address not found",
    EVOUCHER: "Invalid voucher",
    ESERVICE: "Service Unavailable",
    EACTIVE: "There is already an active service at the provided location.",
    EVERIFICATIONCODE: "Invalid verification code",
    EINVALIDPLANS: "Invalid Plans",
    EUNKNOWN: "Unable to find a service at the provided address.",
    "Email is already registered": "Email is already registered",
    "Invalid EWAY_CARDNUMBER": "Invalid card number",
    proper_email: "Please use a proper email",
    "Invalid captcha code provided": "Invalid captcha code provided",
  };
  return (
    <>
      <Modal
        show
        title="Unable to continue"
        onClose={onClose}
        footer={(
          <Row>
            <Col xs="auto" className="ml-auto"></Col>
            <Col xs="auto">
              <Button
                className="vw-button btn btn-white"
                type="button"
                onClick={() => onClose()}>
                {"Close"}
              </Button>
            </Col>
          </Row>
        )}>
        <div className="alert">
          {errArray[error] || error || "An unknown error has occurred please contact support or try again later."}
        </div>
      </Modal>
    </>
  );
}