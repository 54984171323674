import React, {useState} from "react";
import {Row, Col, Container} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import {debounce} from "../utils/index";
import TextField from "../bootstrap/input";
import {setFormData} from "../manager/form";
import {
  findBuildingQuery,
  getFindBuildingResult,
  checkServiceAvailabilityQuery,
  getCheckServiceAvailabilityResult,
} from "../logic/address";
import MapMarkerAltIcon from "../../images/icons/map-marker-alt.svg";
import TimesWhiteImage from "../../images/icons/times-white.svg";
import {console} from "window-or-global";
import {client} from "../main";
import ServiceCheckModal from "./service-check";

export default function AddressAutoComplete(props) {
  const [unitNo, setUnitNo] = useAsyncSetState(null);
  const [addresses, setAddresses] = useAsyncSetState([]);
  const [selectedAddress, setSelectedAddress] = useAsyncSetState(null);
  const [addressError, setAddressError] = useAsyncSetState(false);
  const [unitError, setUnitError] = useAsyncSetState(null);
  const [error, setError] = useAsyncSetState(null);
  const [address, setAddress] = useAsyncSetState("");

  const {highlight, home, init, inline, showLocator, setBuilding} = props;
  const rowClassName = `
    vw-address-locator 
    ${inline ? "inline" : ""} 
    ${highlight ? "highlight" : ""} 
    ${showLocator ? "show-address-locator" : "hide-address-locator"} 
    ${init ? "vw-hide" : ""} 
    magictime-300ms
  `;

  const resetError = () => {
    setAddressError(false);
    setUnitError(false);
  };

  const handleValidSubmit = async () => {
    resetError();
    if (!selectedAddress) {
      return setAddressError(true);
    }
    if (!unitNo) {
      return setUnitError(true);
    }

    try {
      const response = await client.query({
        fetchPolicy: "no-cache",
        query: checkServiceAvailabilityQuery,
        variables: {
          buildingCode: selectedAddress?.code,
          unitNo,
        },
      });

      const serviceAvailability = getCheckServiceAvailabilityResult(response);

      await setFormData({
        building: {...selectedAddress, unitNo},
        unit: unitNo,
        serviceAvailability,
      });
      window.location.href = `/register?building=${selectedAddress.code}&unitno=${unitNo}`;
    } catch (err) {
      let errorMessage =
        err.message.replace(/(GraphQL error:)/gi, "") || err.message;
      return setError(errorMessage || "An error has occured");
    }
  };

  const handleAddressChange = debounce(async (address) => {
    await setAddress(address);
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: findBuildingQuery,
      variables: {address},
    });
    const result = getFindBuildingResult(response);
    console.log({response, result});

    if (!result.length) {
      return setAddressError(true);
    }

    await setAddressError(false);
    return setAddresses(result);
  }, 250);

  const handleAddressSelect = () => {
    setSelectedAddress(null);
    setAddress("");
  };

  return (
    <>
      {error && (
        <ServiceCheckModal error={error} onClose={async () => setError(null)} />
      )}
      <Formsy>
        <div className={rowClassName}>
          <Container fluid className="no-gutters">
            <Row>
              <Col className="vw-al-header">
                {"To get started, check your address"}
              </Col>
            </Row>
            <Row
              className={`${
                home ? "" : "justify-content-center"
              } flex-align-center no-gutters`}
            >
              <Col xs={12} md={9} lg={8} className="mb-1 mb-md-0">
                <div
                  className={`vw-field ${home ? "highlight" : ""} vw-street`}
                >
                  <div
                    className={`vw-field vw-placeholder 
                    ${address !== "" ? "hasValue" : ""}
                    ${selectedAddress !== null ? "d-none" : ""}`}
                  >
                    {"Street Address"}
                  </div>
                  {selectedAddress !== null ? (
                    <div
                      className="vw-al-selected-address-bg"
                      style={{backgroundImage: `url(${MapMarkerAltIcon})`}}
                    >
                      <div
                        className="vw-al-selected-address-label"
                        onClick={() => {
                          handleAddressSelect();
                        }}
                        style={{backgroundImage: `url(${TimesWhiteImage})`}}
                      >
                        {selectedAddress.name}
                      </div>
                    </div>
                  ) : (
                    <input
                      name="address"
                      type="text"
                      onChange={async (e) =>
                        await handleAddressChange(e.target.value)
                      }
                      className={`vw-al-input-field ${
                        addressError ? "border-danger" : ""
                      }`}
                    />
                  )}
                  {selectedAddress === null &&
                    address.trim() !== "" &&
                    addresses.length > 0 && (
                      <div className="vw-al-locations">
                        {addresses.map((address) => (
                          <div
                            key={`address-${address.id}`}
                            className="vw-al-location"
                            style={{
                              backgroundImage: `url(${MapMarkerAltIcon})`,
                            }}
                            onClick={() => setSelectedAddress(address)}
                          >
                            {address.name}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </Col>
              <Col xs={12} md={3} lg={1} className="mb-1 mb-md-0">
                <div className="vw-field input-unit-no">
                  <TextField
                    name="unitNo"
                    className={`${home ? "highlight" : ""}`}
                    inputClass={`vw-field ${unitError ? "border-danger" : ""}`}
                    onChange={(e) => setUnitNo(e.target.value)}
                    placeholder="Unit #"
                    noLabel
                    type="text"
                  />
                </div>
              </Col>
              <Col xs={12} lg={"auto"} className="mt-md-1 mt-lg-0 ml-lg-1">
                <div className="vw-al-button">
                  <button
                    onClick={() => handleValidSubmit()}
                    className={`vw-button btn-blue`} // vw-button btn-login
                  >
                    {!home ? "Check Eligibility" : "Join us"}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Formsy>
    </>
  );
}