import React from "react";
import AddressSelector from "../components/address-selector";
import LogoMonoImage from "../images/vostro-logo-pack/vostronet-logo-cmyk.svg";
import BgCubes from "../images/bg/cubes.jpg";
import {Row, Container, Col} from "react-bootstrap";
import Main from "../components/main";
import SEO from "../components/section/seo";
import ProductSelector from "../components/bootstrap/product-selector";
import {useAsyncSetState} from "use-async-setstate";
import Footer from "../components/section/footer";
import "../style/join-us.scss";

export default function SignUpPage() {
  return (
    <Main>
      <SEO title="Registration- Internet Portal - VostroNet" />
      <JoinUs />
    </Main>
  );
}

function JoinUs(props) {
  const [selectedProduct, setProduct] = useAsyncSetState(null);
  const [selectedBuilding, setBuilding] = useAsyncSetState(null);
  const [step, setStep] = useAsyncSetState({
    addressSelector: true,
    productSelector: false,
    purchase: false,
  });

  const handleSetBuilding = async (data) => {
    await setStep({
      productSelector: true,
      addressSelector: false,
      purchase: false,
    });
    return setBuilding(data);
  };

  return (
    <Container fluid className="no-gutters join-us-page">
      <Container>
        <Row>
          <Col xs={"auto"} className="mx-auto logo">
            <a tabIndex="-1" href="/">
              <img src={LogoMonoImage} />
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="vw-sup-locator">
            <AddressSelector
              isWhite
              inline
              highlight
              showLocator
              onRequestClose={() => {}}
              setBuilding={(data) => handleSetBuilding(data)}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
